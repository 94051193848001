

































import { Component, Prop, Vue } from 'vue-property-decorator';
import Job from '@/models/Job';
import JobUserDataInterface from '@/interfaces/JobUserData.interface';
import { CalendarEvent } from 'vuetify';
import JobOccurrence from '@/models/JobOccurrence.ts';

@Component({
    components: {
        UserInitialsComponent: () => import(
            /* webpackChunkName: "UserInitialsComponent" */
            '@/components/user/UserInitials.component.vue'),
    },
})
export default class JobTooltipComponent extends Vue {
    @Prop()
    public data!: { jobOccurrence: JobOccurrence, userData: JobUserDataInterface[] } & CalendarEvent;

    /**
     * Retrieves the Job time by using a helper function
     * @param job
     */
    public retrieveJobTime = (jobOccurrence: JobOccurrence) => jobOccurrence;
}

